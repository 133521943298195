<template>
  <div v-cloak>
    <!-- Header -->
    <header class="header">
      <div>
        <router-link to="/">
          <img class="header-logo" src="static/assets/logo.svg" alt="Shorty Logo" />
        </router-link>
      </div>
      <div class="center-header">
        <h1>
          <router-link to="/">Shorty</router-link>
        </h1>
        <p>Shorten your URLs with just one click!</p>
      </div>
      <div class="menu">
        <img src="static/assets/menu.svg" alt="Menu" class="menu-inner" />
      </div>
      <div class="links">
        <!-- <a href="#">About</a> -->
        <router-link v-show="!isAuthenticated" to="/login">login</router-link>
        <router-link v-show="isAuthenticated" to="/logout">logout</router-link>
        <router-link to="history">history</router-link>
      </div>
    </header>
    <router-view>loading...</router-view>
    <!-- Footer -->
    <footer class="footer">
      <p>&copy; Shorty - Copyright {{ new Date().getFullYear() }}</p>
      <!-- <p class="bottom">
        This project is open source and hosted on
        <a href="https://github.com/">GitHub</a>.
      </p> -->
    </footer>
  </div>
</template>

<script>
export default {
  computed:{
    isAuthenticated(){
      return this.$store.state.isAuthenticated ;
      
    }
  },
  methods:{
    logout(){
      sessionStorage.setItem('_token_',null);
      this.$store.state.isAuthenticated = false;
//      this.$router.push('/logout');
    }
  },
  mounted(){
    this.$store.state.isAuthenticated = sessionStorage.getItem('_token_');
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
