<template>
  <div class="about">
    <h2>Redirect in progress..</h2>        
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name:"RedirectVue",
  mounted(){
   window.location.href =  `/api/key${this.$route.params.pathMatch}` ;  
  }
}
</script>

<style>
h2{
  text-align: center;
  margin-top: 20px;
}
</style>