import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HistoryViewVue from '../views/HistoryView.vue'
import RedirectVueVue from '../views/RedirectVue.vue'
import LoginView  from "@/views/LoginView.vue";
import CallbackVue from "@/views/CallbackVue.vue";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryViewVue
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/HistoryView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView    
  },
  {
    path: '/callback',
  name: 'callback',
  component: CallbackVue    
},
//  {
//    path: '/.auth/login/github',
//    name: 'githubin',
//    beforeEnter:() => window.location.href = '/.auth/login/github?post_login_redirect_uri=/history'    
//  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter:(to, from, next) => {
      sessionStorage.clear();
      store.state.isAuthenticated = false;
      next("/");
    }    
  },
  
  {
    path: '*',
    component: RedirectVueVue,   
    beforeEnter: (to, from, next) => {
      return next();

      // the beforeEnter guard has access to the route that navigation is being triggered to.
      // console.log("full path:", to.fullPath)
      // console.dir(to)      
      // if (to.fullPath.indexOf('.auth')>=0) next();
      // else window.location.href =  `/api/key/${to.fullPath}` ;    
    }
  }
]






const router =  new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
  
//   if (to.fullPath.indexOf('.auth')>=0) next();
//   else window.location.href =  `/api/key/${to.fullPath}` ;    
  
//   // // Your global navigation guard logic here, for example:
//   // // Check if a route requires auth (meta field in route configuration)
//   // if (to.matched.some(record => record.meta.requiresAuth)) {
//   //   // Check if user is authenticated, here we're just simulating a user auth state
//   //   const userIsAuthenticated = false; // replace this with your auth logic

//   //   if (!userIsAuthenticated) {
//   //     // Redirect to a login page, or anywhere else
//   //     next({ name: 'login' })
//   //   } else {
//   //     next() // go to wherever the user wanted to go
//   //   }
//   // } else {
//   //   next() // make sure to always call next()!
//   // }
// })

export default router
