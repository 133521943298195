<template>
    <!-- Hero section -->
    <main class="hero">
        <!-- Input form -->
        <div class="form" v-if="doLogin">
            <form @submit.prevent="login" method="post" id="main-form">
                <input
                    type="email"
                    name="original_url"
                    autocomplete="off"
                    placeholder="Enter your email"
                    v-model="email"
                    required
                    style="width: 400px; text-align: center"
                />
            </form>
            <button type="submit" form="main-form" class="submit-button">
                {{loginText}}
                <!--<img class="link-logo" src="static/assets/link-icon.svg" />-->
            </button>
        </div>

        <!-- Shortened link -->
        <div class="description" v-show="!doLogin">
            <div>
                <!-- <h2>Check your email for login link</h2>
                you can close this window -->

                <h2 class="tm-login-form">Email with login instructions sent!</h2>
                <p>Go to your email provider to login. Click <a :href="emailUrl"> {{ emailUrl }}</a> !</p>

            </div>
        </div>

        <!-- About cards -->
        <section hidden class="cards-container"></section>
    </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: "HomeView",
    components: {
        // HelloWorld
    },
    computed: {
        emailUrl: function () {
            var splitEmail = this.email.split('@');
            return splitEmail.length > 1 ? `https://${splitEmail[1]}` : '';
        }
    },
    data: function () {
        return {
            email: "",
            error:"",
            doLogin: true,
            loginText : 'Login'
        };
    },
    methods: {       
        async login() {
            if (!this.email) {
                alert("Email required");
                return;
            }
            
            this.loginText = "Loging in ...";

            var resp = await fetch(`/api/login/${this.email}`);
            if (!resp.ok) {
                this.error="Unable to authenticate";
                console.dir(resp);
                return;
            }
            
                this.doLogin = false;
                // alert('An email with a link to login has been sent to your email. Follow the link from the email to login!')
                console.log("closing the window...");
            
        },
        copyText: function () {
            const text = document.getElementById("to-cp").innerText;
            // Creating an input field - this due to JS only being able to copy stuff from 'selected' elements,
            // the only elements where '.select()' can be called on are 'textarea' and 'input'
            const textArea = document.createElement("textarea");

            // Adding 'text' to it, adding it to the body and selecting it
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();

            // Hiding the textarea
            textArea.style.position = "absolute";
            textArea.style.right = "-9999";

            // Copying the text and removing the element
            document.execCommand("copy");
            document.body.removeChild(textArea);
        },
    },
};
</script>
