<template>
    <!-- row -->
    <div class="row tm-mt-big mx-auto">
        <div class="col-12 mx-auto">
            <!-- <div class=" justify-content-center"> -->
            <!-- <img src="/img/landing.png" loading="lazy" class="img-fluid mx-auto d-block"/> -->
            <!-- </div> -->

            <div class="bg-white1 tm-block">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="callback text-center">
                            <h2>Login in progress...</h2>
                            <div v-show="error">{{ error }}</div>
                            <div>{{ this.$route.query.email }}</div>
                            <!-- <p>{{ this.$route.query.token }}</p> -->
                            <div class="links grid-center d-flex align-items-center">
                            <router-link class="m-0" to="/">return home</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'CallbackView',
    data(){return{error:""}},
  methods:{
    async getToken(){
      const query = this.$route.query;
      const resp = await fetch(`/api/verify?email=${query.email}&token=${encodeURIComponent(query.token)}`)
      if (!resp.ok){
            this.error = "Unable to authenticate!"
            console.dir(resp)
            return;
      }
      const token =await resp.text();
      sessionStorage.setItem("_token_", token);
      this.$store.state.isAuthenticated = true;
        
      console.log("token:");
      console.log(token)
      setTimeout(() => {
        this.$router.push("/history");
    }, 1000);
      
    }
  },
  mounted(){
    console.dir(this.$route)
    this.getToken();
  }
}
</script>
<style scoped>

.callback > div{
    margin: 20px;
    text-align: center;
    text-decoration: none;
}

.grid-center {
    display: grid;
    justify-content: center;
}
</style>